import React from "react";
import { useSpring, animated as a, config } from "react-spring";
import { line, curveCardinalClosed, curveBasisClosed } from "d3-shape";

import { polarToCartesian } from "../utils";

const curves = {
  basic: curveBasisClosed,
  cardinal: curveCardinalClosed
};

function lineGenerator(type) {
  if (curves[type]) {
    return line().curve(curves[type]);
  } else {
    return line();
  }
}

const scale = radius => (
  <circle
    key={`scale-${radius}`}
    cx={0}
    cy={0}
    r={radius}
    fill="#fff"
    stroke="#aaa"
    strokeWidth="0.2"
  />
);

const Scales = ({ numberOfScales, chartSize }) => {
  const scales = [];
  for (let i = numberOfScales; i > 0; i--) {
    const radius = ((i / numberOfScales) * chartSize) / 2;
    scales.push(scale(radius));
  }

  return <g id="scales">{scales}</g>;
};

const Blob = ({ shape, ...props }) => {
  const spring = useSpring({
    d: shape,
    config: config.wobbly
  });

  return <a.path {...spring} {...props} />;
};

const RadarChart = React.forwardRef(
  ({ points, chartSize, numberOfScales, curve, image, ...props }, ref) => {
    const shapeGenerator = lineGenerator(curve);

    let shape;
    if (Array.isArray(points)) {
      const polar = points.map((point, i) => ({
        distance: point,
        radians: (Math.PI * 2 * i) / points.length
      }));

      const cartesian = polar.map(({ distance, radians }, i) => {
        return polarToCartesian((distance * chartSize) / 2, radians);
      });

      shape = shapeGenerator(cartesian);
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={chartSize}
        height={chartSize}
        viewBox={`0 0 ${chartSize} ${chartSize}`}
        ref={ref}
      >
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#F54EA2" stopOpacity="1" />
          <stop offset="100%" stopColor="#FF7676" stopOpacity="1" />
        </linearGradient>

        {numberOfScales && (
          <Scales numberOfScales={numberOfScales} chartSize={chartSize} />
        )}

        <mask id="clip" mask-type="alpha">
          {shape && (
            <Blob
              key={`${curve}${points.length}`}
              transform={`translate(${chartSize / 2},${chartSize / 2})`}
              shape={shape}
              // fill="url(#gradient)"
            />
          )}
        </mask>

        <g id="svgContent" mask="url(#clip)">
          {image ? (
            <image
              x="0"
              y="0"
              width={chartSize}
              height={chartSize}
              preserveAspectRatio="xMidYMid slice"
              xlinkHref={image}
            />
          ) : (
            <rect
              x="0"
              y="0"
              width={chartSize}
              height={chartSize}
              fill="url(#gradient)"
            />
          )}
        </g>
      </svg>
    );
  }
);

export default RadarChart;
