import React, { useState, useEffect, useRef } from "react";
import { generateArray } from "./utils";
import { createSvg, createPng, getBase64, download } from "./utils/download";
import RadarChart from "./components/RadarChart";

import "./App.css";
import styles from "./App.module.css";
// import input from "./Range.module.css";

import placeholder from "./images/michele_marconi_desktop_1.jpg";

const App = () => {
  const [complexity, setComplexity] = useState(7);
  const [contrast, setContrast] = useState(0.7);
  const [curve, setCurve] = useState("basic");
  const [image, setImage] = useState(placeholder);
  const [points, generate] = useState(generateArray(complexity, contrast));

  const svgRef = useRef();

  async function downloadImage(ext) {
    const inlineSvg = svgRef.current;
    const svg = createSvg(inlineSvg);

    if (ext === "png") {
      const png = await createPng(inlineSvg);
      download(png, "blob.png");
    } else {
      download(svg, "blob.svg");
    }
  }

  async function handleImage(ev) {
    const file = ev.target.files[0];
    if (file) {
      const url = await getBase64(file);
      setImage(url);
    }
  }

  function randomize() {
    generate(generateArray(complexity, 1 - contrast));
  }

  useEffect(() => {
    randomize();
  }, [complexity, contrast]);

  return (
    <div className={styles.app}>
      <div className={styles.controls}>
        <select value={curve} onChange={ev => setCurve(ev.target.value)}>
          <option value="line">Line</option>
          <option value="basic">Basic</option>
          <option value="cardinal">Cardinal</option>
        </select>
        <input
          id="upload"
          type="file"
          accept="image/*"
          onChange={handleImage}
          hidden
        />
      </div>
      {/* <img className={styles.image} src={image} alt="" /> */}
      <div className={`${styles.flex} ${styles.flexCenter}`}>
        <div
          className={`${styles.flex} ${styles.flexCenter} ${
            styles.rangeComplexity
          }`}
        >
          <input
            className={styles.range}
            type="range"
            min="3"
            max="10"
            value={complexity}
            onChange={ev => setComplexity(+ev.target.value)}
          />
          <span className={styles.label}>{complexity}</span>
        </div>
        <div className={styles.blob}>
          <RadarChart
            ref={svgRef}
            points={points}
            chartSize={500}
            // numberOfScales={3}
            curve={curve}
            image={image}
          />
        </div>
        <div
          className={`${styles.flex} ${styles.flexCenter} ${
            styles.rangeContrast
          }`}
        >
          <span className={styles.label}>{contrast}</span>
          <input
            className={styles.range}
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={contrast}
            onChange={ev => setContrast(+ev.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <label htmlFor="upload" className={styles.button}>
          Choose image
        </label>
        <button className={styles.button} onClick={ev => randomize()}>
          Randomize
        </button>
        <button className={styles.button} onClick={() => downloadImage("png")}>
          Download
        </button>
      </div>

      {/* <div>
        <span>Download </span>
        <a className={styles.link} href="#" onClick={() => downloadImage()}>
          svg
        </a>
        <span> </span>
        <a
          className={styles.link}
          href="#"
          onClick={() => downloadImage("png")}
        >
          png
        </a>
      </div> */}
    </div>
  );
};

export default App;
