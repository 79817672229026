function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function randomFloatingNumber(min, max, decimals) {
  return +(Math.random() * (max - min) + min).toFixed(decimals);
}

export function generateArray(length, min = 0.7, max = 1) {
  return Array(length)
    .fill(0)
    .map(n => randomFloatingNumber(min, max, 2));
}

export function degreesToRadians(deg) {
  return deg * (Math.PI / 180);
}

export function polarToCartesian(distance, angle) {
  const x = distance * Math.cos(angle - Math.PI / 2);
  const y = distance * Math.sin(angle - Math.PI / 2);
  return [x, y];
}
