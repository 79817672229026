function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = err => reject(err);
    img.src = src;
  });
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = err => reject(err);
    reader.readAsDataURL(file);
  });
}

export function createSvg(SVGElement) {
  const data = new XMLSerializer().serializeToString(SVGElement);
  const blob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(blob);

  return url;
}

export async function createPng(SVGElement) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const svg = createSvg(SVGElement);
  const img = await loadImage(svg);

  canvas.width = img.width;
  canvas.height = img.height;

  ctx.drawImage(img, 0, 0);

  URL.revokeObjectURL(svg);
  const url = canvas.toDataURL("image/png");
  return url;
}

export function download(url, filename) {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(element);
}
